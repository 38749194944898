import { Link, useLocation } from "react-router-dom";
import {
  FolderIcon,
  UserIcon,
  CurrencyDollarIcon,
  HomeIcon,
  PhotoIcon,
  TicketIcon,
  EnvelopeIcon,
  PresentationChartBarIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import logo from "../assets/logo.png";
import { FaFileInvoice } from "react-icons/fa";

const getMatchedPage = (path) => {
  return (
    [
      "dashboard",
      "all-users",
      "all-files",
      "solutions",
      "solution-images",
      "credits-ledger",
      "all-tickets",
      "email",
      "login-images",
      "gain",
    ].find((page) => path.includes(page)) || ""
  );
};

const AdminSidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(
    getMatchedPage(location.pathname)
  );

  const handleItemClick = (item) => {
    setSelectedItem(item);
    toggleSidebar();
  };

  return (
    <div
      className={`fixed inset-y-0 left-0 w-64 bg-gray-900 dark:bg-gray-900 text-white dark:text-gray-200 transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 ease-in-out md:relative md:translate-x-0`}
    >
      <div className="flex justify-end md:hidden">
        <button
          onClick={toggleSidebar}
          className="text-white p-4 focus:outline-none"
          aria-label="Close sidebar"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>

      <div className="flex items-center mb-4 p-4">
        <img src={logo} alt="Logo" className="w-8 h-auto mr-4" />
        <h2 className="text-lg font-bold">VDP Tuning Team</h2>
      </div>
      <nav>
        <ul className="">
          <li
            className={`${
              selectedItem === "dashboard"
                ? "bg-gray-700 text-white"
                : "text-gray-500"
            } hover:bg-gray-700 dark:hover:bg-gray-800 hover:text-white mb-2 px-3 font-medium`}
          >
            <Link
              to="/admin/dashboard"
              className="p-2 block flex items-center space-x-3"
              onClick={() => handleItemClick("dashboard")}
            >
              <HomeIcon className="w-5 h-5" />
              <span>Dashboard</span>
            </Link>
          </li>
          <hr className="my-4 border-gray-600" />
          <li
            className={`${
              selectedItem === "all-users"
                ? "bg-gray-700 text-white"
                : "text-gray-500"
            } hover:bg-gray-700 dark:hover:bg-gray-800 hover:text-white mb-2 px-3 font-medium`}
          >
            <Link
              to="/admin/all-users"
              className="p-2 block flex items-center space-x-3"
              onClick={() => handleItemClick("all-users")}
            >
              <UserIcon className="w-5 h-5" />
              <span>All Users</span>
            </Link>
          </li>
          <li
            className={`${
              selectedItem === "all-files"
                ? "bg-gray-700 text-white"
                : "text-gray-500"
            } hover:bg-gray-700 dark:hover:bg-gray-800 hover:text-white mb-2 px-3 font-medium`}
          >
            <Link
              to="/admin/all-files"
              className="p-2 block flex items-center space-x-3"
              onClick={() => handleItemClick("all-files")}
            >
              <FolderIcon className="w-5 h-5" />
              <span>All Files</span>
            </Link>
          </li>
          <li
            className={`${
              selectedItem === "all-tickets"
                ? "bg-gray-700 dark:bg-gray-900 text-white"
                : "text-gray-500"
            } hover:bg-gray-700 dark:hover:bg-gray-900 hover:text-white mb-2 px-3 font-medium`}
          >
            <Link
              to="/admin/all-tickets"
              className="p-2 block flex items-center space-x-3"
              onClick={() => handleItemClick("my-ticket")}
            >
              <TicketIcon className="w-5 h-5" /> {/* Icon for Support */}
              <span>All Tickets</span>
            </Link>
          </li>
          <li
            className={`${
              selectedItem === "credits-ledger"
                ? "bg-gray-700 text-white"
                : "text-gray-500"
            } hover:bg-gray-700 dark:hover:bg-gray-800 hover:text-white mb-2 px-3 font-medium`}
          >
            <Link
              to="/admin/credits-ledger"
              className="p-2 block flex items-center space-x-3"
              onClick={() => handleItemClick("credits-ledger")}
            >
              <FaFileInvoice className="w-5 h-5" />
              <span>All Credits History</span>
            </Link>
          </li>
          <hr className="my-4 border-gray-600" />
          <li
            className={`${
              selectedItem === "solutions"
                ? "bg-gray-700 text-white"
                : "text-gray-500"
            } hover:bg-gray-700 dark:hover:bg-gray-800 hover:text-white mb-2 px-3 font-medium`}
          >
            <Link
              to="/admin/solutions"
              className="p-2 block flex items-center space-x-3"
              onClick={() => handleItemClick("solutions")}
            >
              <CurrencyDollarIcon className="w-5 h-5" />
              <span>Solution Price</span>
            </Link>
          </li>
          <li
            className={`${
              selectedItem === "solution-images"
                ? "bg-gray-700 text-white"
                : "text-gray-500"
            } hover:bg-gray-700 dark:hover:bg-gray-800 hover:text-white mb-2 px-3 font-medium`}
          >
            <Link
              to="/admin/solution-images"
              className="p-2 block flex items-center space-x-3"
              onClick={() => handleItemClick("solution-images")}
            >
              <PhotoIcon className="w-5 h-5" />
              <span>Solution Images</span>
            </Link>
          </li>
          <li
            className={`${
              selectedItem === "login-images"
                ? "bg-gray-700 text-white"
                : "text-gray-500"
            } hover:bg-gray-700 dark:hover:bg-gray-800 hover:text-white mb-2 px-3 font-medium`}
          >
            <Link
              to="/admin/login-images"
              className="p-2 block flex items-center space-x-3"
              onClick={() => handleItemClick("login-images")}
            >
              <PhotoIcon className="w-5 h-5" />
              <span>Login / Register Images</span>
            </Link>
          </li>
          <hr className="my-4 border-gray-600" />

          <li
            className={`${
              selectedItem === "email"
                ? "bg-gray-700 text-white"
                : "text-gray-500"
            } hover:bg-gray-700 dark:hover:bg-gray-800 hover:text-white mb-2 px-3 font-medium`}
          >
            <Link
              to="/admin/email"
              className="p-2 block flex items-center space-x-3"
              onClick={() => handleItemClick("email")}
            >
              <EnvelopeIcon className="w-5 h-5" />
              <span>Email</span>
            </Link>
          </li>
          <hr className="my-4 border-gray-600" />
          <li
            className={`${
              selectedItem === "gain"
                ? "bg-gray-700 dark:bg-gray-900 text-white"
                : "text-gray-500"
            } hover:bg-gray-700 dark:hover:bg-gray-900 hover:text-white mb-2 px-3 font-medium`}
          >
            <Link
              to="/admin/gain"
              className="p-2 block flex items-center space-x-3"
              onClick={() => handleItemClick("gain")}
            >
              <PresentationChartBarIcon className="w-5 h-5" />
              <span>Gain</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default AdminSidebar;
