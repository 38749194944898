import { useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import AdminSidebar from "./components/AdminSidebar";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import FileUpload from "./pages/FileUpload";
import MyFiles from "./pages/MyFiles";
import MyTicket from "./pages/MyTicket";
import BuyCredits from "./pages/BuyCredits";

import AllUsers from "./pages/admin/AllUsers";
import AllFiles from "./pages/admin/AllFiles";
import ViewFile from "./pages/admin/ViewFile";
import CreditsLedger from "./pages/admin/CreditsLedger";
import Solution from "./pages/admin/Solution";

import { AuthProvider, AuthContext } from "./context/AuthContext";
import FileDetails from "./pages/FileDetails";
import AddSolution from "./pages/admin/AddSolution";
import EditUser from "./pages/admin/EditUser";
import AccountSettings from "./pages/AccountSettings";
import Dashboard from "./pages/Dashboard";
import AdminDashboard from "./pages/admin/AdminDashboard";
import EditSolution from "./pages/admin/EditSolution";
import SolutionImage from "./pages/admin/SolutionImage";
import LoginImages from "./pages/admin/LoginImages";
import ResetPassword from "./pages/ResetPassword";
import CreateTicket from "./pages/CreateTicket";
import AllTickets from "./pages/admin/AllTickets";
import PriceList from "./pages/PriceList";
import GainCar from "./pages/GainCar";
import EmailForm from "./pages/admin/EmailForm";
import MyCreditsHistory from "./pages/MyCreditsHistory";

const ProtectedRoute = ({ element, allowedRole }) => {
  const { isAuthenticated, user, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (allowedRole && user && user.role !== allowedRole) {
    return <Navigate to="/login" />;
  }
  return element;
};

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {/* Protected User Routes */}
          <Route
            path="*"
            element={
              <ProtectedRoute
                element={
                  <div className="min-h-screen flex">
                    <Sidebar
                      isOpen={isSidebarOpen}
                      toggleSidebar={toggleSidebar}
                    />
                    <div className="flex-1 flex flex-col">
                      <Header toggleSidebar={toggleSidebar} />
                      <main className="flex-grow p-4 bg-gray-200 dark:bg-gray-900">
                        <Routes>
                          <Route path="/dashboard" element={<Dashboard />} />
                          <Route path="/upload" element={<FileUpload />} />
                          <Route path="/my-files" element={<MyFiles />} />
                          <Route path="/my-ticket" element={<MyTicket />} />
                          <Route path="/price-list" element={<PriceList />} />
                          <Route path="/gain" element={<GainCar />} />
                          <Route
                            path="/my-credits-history"
                            element={<MyCreditsHistory />}
                          />
                          <Route
                            path="/create-ticket/:id"
                            element={<CreateTicket />}
                          />
                          <Route path="/buy-credits" element={<BuyCredits />} />
                          <Route
                            path="/view-file/:id"
                            element={<FileDetails />}
                          />
                          <Route
                            path="/account-settings"
                            element={<AccountSettings />}
                          />
                          <Route
                            path="*"
                            element={<Navigate to="/dashboard" />}
                          />
                        </Routes>
                      </main>
                      <Footer />
                    </div>
                  </div>
                }
                allowedRole="customer"
              />
            }
          />

          {/* Protected Admin Routes */}
          <Route
            path="/admin/*"
            element={
              <ProtectedRoute
                element={
                  <div className="min-h-screen flex">
                    <AdminSidebar
                      isOpen={isSidebarOpen}
                      toggleSidebar={toggleSidebar}
                    />
                    <div className="flex-1 flex flex-col">
                      <Header toggleSidebar={toggleSidebar} />
                      <main className="flex-grow p-4 bg-gray-200">
                        <Routes>
                          <Route
                            path="dashboard"
                            element={<AdminDashboard />}
                          />
                          <Route path="all-users" element={<AllUsers />} />
                          <Route path="all-files" element={<AllFiles />} />
                          <Route
                            path="credits-ledger"
                            element={<CreditsLedger />}
                          />
                          <Route path="solutions" element={<Solution />} />
                          <Route
                            path="solution-images"
                            element={<SolutionImage />}
                          />
                          <Route
                            path="login-images"
                            element={<LoginImages />}
                          />
                          <Route path="all-tickets" element={<AllTickets />} />
                          <Route path="view-file/:id" element={<ViewFile />} />
                          <Route path="edit-user/:id" element={<EditUser />} />
                          <Route
                            path="add-solution/"
                            element={<AddSolution />}
                          />
                          <Route
                            path="edit-solution/:id"
                            element={<EditSolution />}
                          />
                          <Route
                            path="account-settings"
                            element={<AccountSettings />}
                          />
                          <Route
                            path="create-ticket/:id"
                            element={<CreateTicket />}
                          />
                          <Route path="email" element={<EmailForm />} />
                          <Route path="gain" element={<GainCar />} />
                          <Route
                            path="*"
                            element={<Navigate to="/admin/dashboard" />}
                          />
                        </Routes>
                      </main>
                      <Footer />
                    </div>
                  </div>
                }
                allowedRole="admin"
              />
            }
          />

          {/* Fallback Route */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
