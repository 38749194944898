import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import axiosInstance from "../utils/axiosInstance";
import ErrorAlert from "../components/ErrorAlert";
import { countryOptions } from "../utils/Countries";
import logo from "../assets/logo.png";
import Loader from "../components/Loader";

const Register = () => {
  const [error, setError] = useState(null);
  const { register, background, isBackgroundLoaded } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [country, setCountry] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    const countryData = countryOptions.find(
      (option) => option.name === selectedCountry
    );
    setCountry(selectedCountry);
    setPhoneCode(countryData ? countryData.code : "");
  };

  const evaluatePasswordStrength = (password) => {
    let strength = "weak";
    if (password.length >= 8) {
      if (
        /[A-Z]/.test(password) &&
        /[a-z]/.test(password) &&
        /\d/.test(password) &&
        /[!@#$%^&*(),.?":{}|<>]/.test(password)
      ) {
        strength = "strong";
      } else if (
        /[A-Z]/.test(password) ||
        /\d/.test(password) ||
        /[!@#$%^&*(),.?":{}|<>]/.test(password)
      ) {
        strength = "medium";
      }
    }
    return strength;
  };

  const handlePasswordChange = (e) => {
    const pwd = e.target.value;
    setPassword(pwd);
    const strength = evaluatePasswordStrength(pwd);
    setPasswordStrength(strength);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    const newUser = {
      username,
      email,
      password,
      phone: `${phoneCode}${phone}`,
      country,
      credit_balance: 0,
      role: "CUSTOMER",
      status: "ACTIVE",
    };

    try {
      setSubmitLoading(true);
      const response = await axiosInstance.post("/register", newUser);

      if (response.status === 201 || response.status === 200) {
        register(newUser, navigate);
      } else {
        setError("Registration failed");
      }
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      if (error.response?.status === 400) {
        setError(error.response.data.message);
        return;
      }
      setError("An error occurred during registration");
    }
  };

  const getPasswordStrengthColor = () => {
    if (passwordStrength === "strong") return "text-green-500";
    if (passwordStrength === "medium") return "text-yellow-500";
    return "text-red-500";
  };

  return (
    <div className={`min-h-screen flex bg-white dark:bg-gray-900`}>
      <div className="relative w-2/3 bg-gray-700">
        {!isBackgroundLoaded ? (
          <div className="absolute inset-0 flex items-center justify-center bg-white z-10">
            <Loader />
          </div>
        ) : background.type === "IMAGE" ? (
          <div
            className={`w-full h-full bg-cover bg-center transition-opacity duration-500 ${
              isBackgroundLoaded ? "opacity-100" : "opacity-0"
            }`}
            style={{
              backgroundImage: `url("${background?.url}")`,
            }}
          ></div>
        ) : (
          <video
            className={`w-full h-full bg-cover bg-center transition-opacity duration-500 ${
              isBackgroundLoaded ? "opacity-100" : "opacity-0"
            }`}
            width="600"
            autoPlay
            muted
            loop
            preload="metadata"
          >
            <source src={background?.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>

      {/* Right Section - Register Form */}
      <div className="w-2/3 flex items-center justify-center">
        <div className="w-full max-w-md px-8 py-10 bg-white shadow-lg rounded-lg dark:bg-gray-800">
          <div className="flex flex-col items-center mb-4">
            <img src={logo} alt="Logo" className="w-12 h-auto mb-4" />
            <h1 className="text-3xl font-bold text-red-600">VDP Tuning Team</h1>
          </div>
          <h2 className="text-2xl font-bold text-center text-gray-800 dark:text-white mb-4">
            Create Account
          </h2>
          <p className="text-sm text-center text-gray-600 dark:text-gray-400 mb-6">
            Fill in the information to create a new account.
          </p>
          {error && <ErrorAlert errorMessage={error} />}
          <br />
          {/* Form */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-red-500 focus:border-red-500"
                required
              />
            </div>
            <div>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-red-500 focus:border-red-500"
                required
              />
            </div>

            {/* Country input field */}
            <div>
              <select
                value={country}
                onChange={handleCountryChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-red-500 focus:border-red-500"
                required
              >
                <option value="" disabled>
                  Select Country
                </option>
                {countryOptions.map((option) => (
                  <option key={option.name} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Phone input field */}
            <div className="flex space-x-2">
              <div className="w-1/4">
                <input
                  type="text"
                  value={phoneCode}
                  placeholder="+49"
                  onChange={(e) => setPhoneCode(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-red-500 focus:border-red-500"
                  required
                  readOnly
                />
              </div>
              <div className="w-3/4">
                <input
                  type="tel"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-red-500 focus:border-red-500"
                  required
                />
              </div>
            </div>

            <div>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-red-500 focus:border-red-500"
                required
              />
              <p className={`mt-1 ${getPasswordStrengthColor()}`}>
                {passwordStrength
                  ? `Password strength: ${passwordStrength}`
                  : ""}
              </p>
            </div>
            <div>
              <input
                type="password"
                placeholder="Confirm Password"
                className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-red-500 focus:border-red-500"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <div className="mt-6 text-center">
              <button
                type="submit"
                className="w-full py-2 bg-gray-200 text-gray-600 font-bold rounded-md hover:bg-gray-300"
                disabled={
                  submitLoading ||
                  passwordStrength === "" ||
                  passwordStrength === "weak"
                }
              >
                {submitLoading ? "Sending..." : "CREATE NEW ACCOUNT"}
              </button>
            </div>
          </form>

          <div className="mt-4 text-center">
            <p className="text-gray-600 dark:text-gray-400">
              Already have an account?{" "}
              <a href="/login" className="text-red-600 hover:underline">
                Login
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
