import React, { useEffect, useState } from "react";

const TimezoneInfo = ({ adminTimezone }) => {
  const [clientTimezone, setClientTimezone] = useState("");
  const [adminStatus, setAdminStatus] = useState("offline");
  const [badgeColor, setBadgeColor] = useState("bg-red-500");
  const [textColor, setTextColor] = useState("text-white");

  const isWithinWorkingHours = (timezone) => {
    const currentTime = new Date().toLocaleTimeString("en-US", {
      timeZone: timezone,
      hour12: false,
    });
    const [hour] = currentTime.split(":").map(Number);
    return hour >= 8 && hour < 24;
  };

  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setClientTimezone(timezone);
    const isOnline = isWithinWorkingHours(adminTimezone);
    if (isOnline) {
      setAdminStatus("online");
      setBadgeColor("bg-white");
      setTextColor("text-green-500");
    } else {
      setAdminStatus("offline");
      setBadgeColor("bg-white");
      setTextColor("text-red-500");
    }
  }, [adminTimezone]);

  return (
    <div className="flex justify-between items-center bg-blue-500 dark:bg-gray-700 text-white rounded-full shadow-md">
      <div className="font-semibold px-2 pr-1">Admin</div>
      <div
        className={`${badgeColor} ${textColor} font-bold px-3 py-1 rounded-full`}
      >
        {adminStatus}
      </div>
    </div>
  );
};

export default TimezoneInfo;
